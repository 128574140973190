import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDebounce, useEffectOnce } from "usehooks-ts";

import { LABELS } from "../../../app/constants/TextConstants";
import { ValidationConstants } from "../../../app/constants/ValidationConstants";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { AddUserForm } from "../../../generated/operation-result-types";
import {
  useAddUserMutation,
  useGetPermissionsQuery,
  useGetUserRoleEnumQuery,
} from "../../../slices/UserSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import classNames from "classnames";
import CustomSelect from "../../../components/select/Select";
import { IUserRoleEnum, SelectOptionType } from "../../../types/types";
import { getInitialsFromFullName } from "../../../constants/constants";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import { ViewContactList } from "./ViewContactList";
import { useAppSelector } from "../../../redux/redux";

export const AddUserView: React.FC<unknown> = () => {
  const { user } = useAppSelector((state) => state.appReducer);

  const [role, setRole] = useState<string>("");
  const [assignedAll, setAssignedAll] = useState(true);
  const [assignedContacts, setAssignedContacts] = useState<SelectOptionType[]>(
    [],
  );
  const [userValue, setUserValue] = useState("");
  const [viewList, setViewList] = useState(false);

  const searchContactsDebounce = useDebounce(userValue, 300);

  const { data: userRoleEnum, isLoading } = useGetUserRoleEnumQuery();
  const [addUser, { isSuccess, error }] = useAddUserMutation();
  const { data: permissions } = useGetPermissionsQuery();
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      query: searchContactsDebounce || "a",
      userId: undefined,
    });

  useEffectOnce(() => {
    document.title = GetPageTitle("Add user");
  });

  useEffect(() => {
    setRole(user.userRoleEnum.userRole);
    setSelectedPermissions(user.permissionIds);
    setAssignedAll(user.assignedAllClients);
  }, [user.id]);

  useEffect(() => {
    if (role === "Admin" && permissions) {
      setSelectedPermissions(permissions.map((i) => i.id));
    }
    if (role === "Staff (No Settings)") {
      setSelectedPermissions(
        permissions
          ?.filter((i) => i.id !== "3476ece8-0485-4b7f-98b1-378199bf8767")
          .map((i) => i.id) || [],
      );
    }
    if (role === "Staff (No Settings, No Billing)") {
      setSelectedPermissions(
        permissions
          ?.filter(
            (i) =>
              i.id !== "3476ece8-0485-4b7f-98b1-378199bf8767" &&
              i.id !== "434d4588-3532-4e3a-ac3c-d294ef2d76a6",
          )
          .map((i) => i.id) || [],
      );
    }
    if (role === "Custom" && permissions) {
      setSelectedPermissions(user.permissionIds || []);
    }
  }, [role, user, permissions]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddUserForm>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      userRole: 0,
    },
  });
  const clientOptions = useMemo((): SelectOptionType[] => {
    if (!contacts) {
      return [];
    }
    return contacts.map((contact) => ({
      value: contact.id,
      label: contact.name,
      avatar: getInitialsFromFullName(contact.name),
      avatarColor: contact.businessContactId ? "purple" : "blue",
    }));
  }, [contacts]);

  const sortedRoles: IUserRoleEnum[] = [...(userRoleEnum || [])].sort(
    (a, b) => {
      if (a.userRole === "Custom") return 1;
      if (b.userRole === "Custom") return -1;
      return 0;
    },
  );
  const onSubmit: SubmitHandler<AddUserForm> = async (data) => {
    addUser({
      orgId: getLinkedFirm()?.orgId || "",
      userRoleEnumId: userRoleEnum?.[data.userRole].id || "",
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      assignedAllClients: assignedAll,
      permissionIds: selectedPermissions,
      assignedCustomerIds: assignedAll
        ? []
        : assignedContacts.map((i) => i.value),
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("../");
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <div className={"pb-4 text-xl font-semibold"}>Add user</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection name={"User detail"} weightTitle={"bold"}>
          <div className={"form-control mb-8 w-[76%] flex-row"}>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>First name</span>
              </label>
              <input
                type={"text"}
                {...register("firstName", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.firstName?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.firstName?.message}
                </div>
              )}
            </div>
            <div className={"w-1/2"}>
              <label className={"label"}>
                <span className={"label-text"}>Last name</span>
              </label>
              <input
                type={"text"}
                {...register("lastName", {
                  required: LABELS.required,
                  ...ValidationConstants.orgRules.nameRule.valueLength,
                })}
                className={"input input-bordered"}
              />
              {errors.lastName?.message && (
                <div className={"pt-2 text-sm font-bold text-error"}>
                  {errors.lastName?.message}
                </div>
              )}
            </div>
          </div>
          <div className={"form-control mb-8 w-[55%]"}>
            <label className={"label"}>
              <span className={"label-text"}>Email</span>
            </label>
            <input
              type={"text"}
              {...register("email", {
                required: LABELS.required,
                ...ValidationConstants.orgRules.nameRule.valueLength,
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                  message: "Invalid email format",
                },
              })}
              className={"input input-bordered"}
            />
            {(errors.email?.message || error) && (
              <div className={"pt-2 text-sm font-bold text-error"}>
                {errors.email?.message || error}
              </div>
            )}
          </div>

          <div className={"form-control mb-8 w-full"}>
            <label className={"label"}>
              <span className={"label-text"}>Preset roles:</span>
            </label>
            <select
              className={"select select-bordered w-[55%]"}
              value={role}
              onChange={(e) => setRole(e.target.value)}>
              {sortedRoles?.map((item) => (
                <option key={item.id} value={item.userRole}>
                  {item.userRole}
                </option>
              ))}
            </select>
            <div>
              <div className={"my-4 text-base font-semibold"}>Permissions:</div>
              {permissions?.map((item) => (
                <div key={item.id}>
                  <div className={"grid grid-cols-[200px_200px] items-center"}>
                    <div className={"label-text mr-4 h-6"}>
                      {item.permission}
                    </div>
                    <div className={"flex items-center"}>
                      <input
                        type="checkbox"
                        className={classNames(
                          "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]",
                          selectedPermissions.includes(item.id)
                            ? "border-[#7c66f0] bg-[#7c66f0]"
                            : "border-gray-300 bg-gray-300",
                        )}
                        checked={selectedPermissions.includes(item.id)}
                        onChange={(e) => {
                          if (role === "Admin") {
                            return;
                          }
                          if (role === "Staff (No Settings)") {
                            return;
                          }
                          if (role === "Staff (No Settings, No Billing)") {
                            return;
                          }
                          const checked = e.target.checked;
                          setSelectedPermissions((prev) =>
                            !checked
                              ? prev.filter((i) => i !== item.id)
                              : [...prev, item.id],
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div className={"my-4 text-base font-semibold"}>
                Client access:
              </div>
              <div className={"grid grid-cols-[200px_200px] items-center"}>
                <div className={"label-text mr-4"}>All contacts</div>
                <div className={"flex items-center"}>
                  <input
                    type="checkbox"
                    className={classNames(
                      "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]",
                      assignedAll
                        ? "border-[#7c66f0] bg-[#7c66f0]"
                        : "border-gray-300 bg-gray-300",
                    )}
                    checked={assignedAll}
                    onChange={(e) =>
                      e.target.checked
                        ? setAssignedAll(true)
                        : setAssignedAll(false)
                    }
                  />
                </div>
              </div>
              {!assignedAll && (
                <>
                  <div
                    className={"label-text mb-1 mr-4 mt-2 pl-1 text-gray-500"}>
                    Select client(s)
                  </div>
                  <div className={"flex items-center gap-3"}>
                    <div className={"mr-4 h-[42px] w-[301px]"}>
                      <CustomSelect
                        options={clientOptions}
                        value={assignedContacts}
                        onChange={(newValue) => {
                          const value = newValue as SelectOptionType[];
                          setAssignedContacts(value);
                        }}
                        inputValue={userValue}
                        onChangeInput={setUserValue}
                        isMulti
                        isLoading={isLoadingContacts}
                      />
                    </div>
                    <div
                      className={"cursor-pointer text-base text-gray-500"}
                      onClick={() => setViewList(true)}>
                      List view
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </FormSection>

        <div className={"flex justify-start space-x-4 pb-4"}>
          <Button
            colorType={"secondary"}
            label={"Add user"}
            buttonType={"submit"}
            extraClasses={"normal-case"}
          />
          <OutlineButton
            colorType={"neutral"}
            label={"Cancel"}
            onClick={() => navigate("../")}
            extraClasses={"normal-case"}
          />
        </div>
      </form>
      {viewList && (
        <ViewContactList
          contacts={contacts}
          isActive={viewList}
          user={user}
          isLoadingContacts={isLoadingContacts}
          assignedContacts={assignedContacts}
          setAssignedContacts={setAssignedContacts}
          userValue={userValue}
          setUserValue={setUserValue}
          closeEdit={() => setViewList(false)}
        />
      )}
    </>
  );
};
